import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../../../assets/images/favicon.svg'
import Loader from '../../../../components/loader/Loader'
import { post } from '../../../../utilities/API/api'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import { useParams } from 'react-router-dom'

function DebitUser({ notify }) {
  const { id } = useParams()
  const { userDataForAdmin, setShowDebitUser } = useGlobalContext()
  const Token = sessionStorage.getItem('AdminToken')
  const [formLoading, setFormLoading] = useState(false)
  //////////////
  const [steps, setSteps] = useState(1)
  const [amount, setAmount] = useState(1)
  const [transferType, setTransferType] = useState('')
  const [bankName, setBankName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [accountType, setAccountType] = useState('')
  const [routingNumber, setRoutingNumber] = useState('')
  const [description, setDescription] = useState('')
  const [address, setAddress] = useState('')
  const [bankAddress, setBankAddress] = useState('')
  const [swift, setSwift] = useState('')
  const [recipientFullName, setRecipientFullName] = useState('')
  const [pin, setPin] = useState('')

  function submit() {
    if (amount < 1) {
      notify('error', 'amount is compulsory')
    } else {
      setFormLoading(true)
      const postData = {
        id: id,
        amount: amount,
        debit_type: transferType,
        recipeint_bank_name: bankName,
        account_type: accountType,
        routing_number: routingNumber,
        account_number: accountNumber,
        description: description,
        recipeint_name: recipientFullName,
      }

      if (transferType === 'international') {
        postData.recipeint_address = address
        postData.recipeint_bank_address = bankAddress
        postData.swift_bic_code = swift
      }
      post(`admin/debit`, postData, Token)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
            setFormLoading(false)
          } else {
            notify('success', 'Transfer initiated.')
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          }
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  function next1() {
    if (amount > userDataForAdmin.balance) {
      notify('error', 'Amount cannot me more than balance.')
    } else if (amount < 1) {
      notify('error', 'Amount cannot be empty')
    } else if (transferType.length < 1) {
      notify('error', 'Select a transfer type.')
    } else {
      setSteps(2)
    }
  }
  function next2() {
    if (bankName.length < 1) {
      notify('error', 'Bank Name cannot be empty')
    } else if (accountNumber.length < 1) {
      notify('error', 'Account Number cannot be empty')
    } else if (routingNumber.length < 1) {
      notify('error', 'Routing Number cannot be empty')
    } else if (accountType.length < 1) {
      notify('error', 'Select an account type')
    } else if (description.length < 5) {
      notify('error', 'Description must more than 5 characters.')
    } else {
      setSteps(3)
    }
  }
  function next3() {
    if (transferType === 'local') {
      submit()
    } else if (address.length < 1) {
      notify('error', 'Recipient address cannot be empty')
    } else if (bankAddress.length < 1) {
      notify('error', 'Recipient bank address cannot be empty')
    } else {
      submit()
    }
  }

  return (
    <div className='modal-type flex justify-center'>
      <div className='modal-body'>
        <div className='top '>
          <Logo />
          <h1>
            {transferType === 'local'
              ? 'Local'
              : transferType === 'international'
              ? 'International'
              : ''}{' '}
            Transfer
          </h1>
        </div>
        {steps === 1 && (
          <div className='form'>
            <div className='form-card'>
              <p>Amount</p>
              <input
                type='number'
                onChange={(e) => setAmount(e.target.value)}
                min={1}
                value={amount}
              />
            </div>
            <div className='form-card'>
              <p>Transfer Type</p>
              <select
                name=''
                id=''
                onChange={(e) => setTransferType(e.target.value)}
                value={transferType}
              >
                <option value=''>- select - </option>
                <option value='local'>Local Transfer</option>
                <option value='international'>International Transfer</option>
              </select>
            </div>
            <div className='form-card button'>
              <button
                className='danger'
                onClick={() => setShowDebitUser(false)}
              >
                Cancel
              </button>
              <button onClick={next1}>Next</button>
            </div>
          </div>
        )}
        {steps === 2 && (
          <div className='form'>
            <div className='form-card'>
              <p>Recipient full name</p>
              <input
                type='text'
                onChange={(e) => setRecipientFullName(e.target.value)}
                value={recipientFullName}
              />
            </div>
            <div className='form-card'>
              <p>Recipient bank name</p>
              <input
                type='text'
                onChange={(e) => setBankName(e.target.value)}
                value={bankName}
              />
            </div>
            <div className='form-card'>
              <p>Recipient account number</p>
              <input
                type='tel'
                onChange={(e) => setAccountNumber(e.target.value)}
                value={accountNumber}
              />
            </div>
            <div className='form-card'>
              <p>Recipient routing number</p>
              <input
                type='tel'
                onChange={(e) => setRoutingNumber(e.target.value)}
                value={routingNumber}
              />
            </div>
            <div className='form-card'>
              <p>Account Type</p>
              <select
                name=''
                id=''
                onChange={(e) => setAccountType(e.target.value)}
                value={accountType}
              >
                <option value=''>- select - </option>
                <option value='checking'>Checking account</option>
                <option value='savings'>Savings Account</option>
              </select>
            </div>
            <div className='form-card'>
              <p>Description</p>
              <input
                type='text'
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>
            {formLoading ? (
              <Loader location='center' />
            ) : (
              <div className='form-card button'>
                <button className='danger' onClick={() => setSteps(1)}>
                  Back
                </button>

                <button onClick={next2}>Next</button>
              </div>
            )}
          </div>
        )}
        {steps === 3 && (
          <div className='form'>
            {transferType !== 'local' && (
              <>
                <div className='form-card'>
                  <p>Recipient address</p>
                  <input
                    type='text'
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                  />
                </div>
                <div className='form-card'>
                  <p>Recipient bank address</p>
                  <input
                    type='text'
                    onChange={(e) => setBankAddress(e.target.value)}
                    value={bankAddress}
                  />
                </div>
                <div className='form-card'>
                  <p>Recipient bank's SWIFT or BIC code</p>
                  <input
                    type='text'
                    onChange={(e) => setSwift(e.target.value)}
                    value={swift}
                  />
                </div>
              </>
            )}
            {formLoading ? (
              <Loader location='center' />
            ) : (
              <div className='form-card button'>
                <button className='danger' onClick={() => setSteps(2)}>
                  Back
                </button>
                <button onClick={next3}>Submit</button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default DebitUser
