import React, { useContext, useState } from 'react'
import { get } from '../API/api'
import { toast } from 'react-toastify'

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const userToken = sessionStorage.getItem('UserToken')
  const [toggleSidebar, setToggleSidebar] = useState('false')
  const [verifyData, setVerifyData] = useState(null)
  const [userProfile, setUserProfile] = useState({})
  const [profileLoading, setProfileLoading] = useState(true)
  const [showCreditUser, setShowCreditUser] = useState(false)
  const [showDebitUser, setShowDebitUser] = useState(false)
  const [userTransactions, setUserTransactions] = useState({})
  const [userTransactionsLoading, setUserTransactionsLoading] = useState(true)
  const [receiptData, setReceiptData] = useState(null)
  const [initiateTransfer, setInitiateTransfer] = useState(false)
  const [showLoan, setShowLoan] = useState(false)
  const [showSavings, setShowSavings] = useState(false)
  const [showTransferSavings, setShowTransferSavings] = useState(false)
  const [showProfileImage, setShowProfileImage] = useState(false)
  const [showCard, setShowCard] = useState('')
  const [restrictUser, setRestrictUser] = useState(null)
  const [userDataForAdmin, setUserDataForAdmin] = useState({})

  const fetchUser = async () => {
    setProfileLoading(true)
    get('users/profile', userToken).then((result) => {
      if (Object.keys(result).includes('error')) {
        toast.error(result.error)
        setProfileLoading(false)
      } else {
        setUserProfile(result)
        setProfileLoading(false)
      }
    })
  }
  const fetchUserTransactions = async (page) => {
    setUserTransactionsLoading(true)
    get(`users/transfer/history?pageSize=10&page=${page}`, userToken).then(
      (result) => {
        if (Object.keys(result).includes('error')) {
          toast.error(result.error)
          setUserTransactionsLoading(false)
        } else {
          setUserTransactions(result)
          setUserTransactionsLoading(false)
        }
      }
    )
  }
  const handleSidebarToggle = () => {
    setToggleSidebar(!toggleSidebar)
  }
  const closeOnOutsideClick = () => {
    if (!toggleSidebar) {
      setToggleSidebar(!toggleSidebar)
    }
  }
  function addComma(num) {
    const value = /* prettier-ignore */ (num).toLocaleString()
    return value
  }
  return (
    <AppContext.Provider
      value={{
        userDataForAdmin,
        setUserDataForAdmin,
        restrictUser,
        setRestrictUser,
        showCard,
        setShowCard,
        showProfileImage,
        setShowProfileImage,
        showTransferSavings,
        setShowTransferSavings,
        addComma,
        showSavings,
        setShowSavings,
        showLoan,
        setShowLoan,
        initiateTransfer,
        setInitiateTransfer,
        receiptData,
        setReceiptData,
        setUserTransactionsLoading,
        fetchUserTransactions,
        userTransactionsLoading,
        userTransactions,
        showCreditUser,
        setShowCreditUser,
        showDebitUser,
        setShowDebitUser,
        handleSidebarToggle,
        closeOnOutsideClick,
        toggleSidebar,
        setVerifyData,
        verifyData,
        userProfile,
        fetchUser,
        profileLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
